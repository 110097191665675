import React from "react";

import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";

const Footer = () => {
  return (
    <footer className="footer section">
      <div className="footer__container bd-container bd-grid">
        <div className="footer__content">
          <h3 className="footer__title">
            <a href="mailto: contact@usefuldata.com" className="footer__logo">
              Contact
            </a>
          </h3>
          <p className="footer__description">contact@usefuldata.com</p>
          <br />
          <p className="footer__description">
            66 avenue des Champs-Elysées 75008 PARIS
          </p>
        </div>
        <div className="footer__content">
          <h3 className="footer__title">Navigation</h3>
          <ul>
            <li>
              <a href="#home" className="footer__link">
                Home
              </a>
            </li>
            <li>
              <a href="#about" className="footer__link">
                About
              </a>
            </li>
            <li>
              <a href="#services" className="footer__link">
                Services
              </a>
            </li>
            <li>
              <a href="#technologies" className="footer__link">
                Technologies
              </a>
            </li>
            <li>
              <a href="#contact" className="footer__link">
                Contact
              </a>
            </li>
          </ul>
        </div>
        <div className="footer__content">
          <h3 className="footer__title">Services</h3>
          <ul>
            <li>
              <a href="#services" className="footer__link">
                Data Architecture and BI Reporting
              </a>
            </li>
            <li>
              <a href="#services" className="footer__link">
                Custom Business Application Development
              </a>
            </li>
            <li>
              <a href="#services" className="footer__link">
                Cloud Architecture
              </a>
            </li>
            <li>
              <a href="#services" className="footer__link">
                DevOps and Agile Integration
              </a>
            </li>
            <li>
              <a href="#services" className="footer__link">
                IT Strategy Services
              </a>
            </li>
            <li>
              <a href="#services" className="footer__link">
                Enterprise Application Integration
              </a>
            </li>
          </ul>
        </div>
        <div className="footer__content">
          <h3 className="footer__title">Social</h3>
          <a href="#" className="footer__social">
            <FacebookIcon />
          </a>
          <a href="#" className="footer__social">
            <TwitterIcon />
          </a>
          <a href="#" className="footer__social">
            <LinkedInIcon />
          </a>
        </div>
      </div>
      <p className="footer__copy">&#169; 2021 UsefulData. All right reserved</p>
    </footer>
  );
};

export default Footer;
