import React from "react";

const Technologies = () => {
  return (
    <section className="accessory section bd-container" id="accessory">
      <h2 className="section-title">
        Our Technology <br /> Partners
      </h2>
      <p>
        With over 20 certifications under our belts, UsefulData engineers,
        developers, and testers integrate legacy applications with the world’s
        leading cloud technology platforms.
      </p>
      <br />
      <div className="accessory__container bd-grid">
        <div className="accessory__content">
          <img
            src="assets/img/google-cloud.svg"
            alt="google cloud logo"
            className="accessory__img"
          />
        </div>
        <div className="accessory__content">
          <img
            src="assets/img/aws.svg"
            alt="amazon well services logo"
            className="accessory__img"
          />
        </div>
        <div className="accessory__content">
          <img
            src="assets/img/azure.svg"
            alt="microsoft azure logo"
            className="accessory__img"
          />
        </div>
        <div className="accessory__content">
          <img
            src="assets/img/msp.svg"
            alt="microsoft partner logo"
            className="accessory__img"
          />
        </div>
      </div>
      <h2 className="section-title">Open Source</h2>
      <p>
        Here’s the list of technologies most frequently used in our big data
        projects :
      </p>
      <br />
      <div className="decoration__container bd-grid">
        <div className="accessory__content">
          <img
            src="assets/img/python.svg"
            alt="python logo"
            className="accessory__img"
          />
        </div>
        <div className="accessory__content">
          <img
            src="assets/img/tensorflow.svg"
            alt="tensorflow logo"
            className="accessory__img"
          />
        </div>
        <div className="accessory__content">
          <img
            src="assets/img/matplotlib.svg"
            alt="matplotlib logo"
            className="accessory__img"
          />
        </div>
        <div className="accessory__content">
          <img
            src="assets/img/numpy.svg"
            alt="numpy logo"
            className="accessory__img"
          />
        </div>
        <div className="accessory__content">
          <img
            src="assets/img/pandas.svg"
            alt="pandas logo"
            className="accessory__img"
          />
        </div>
        <div className="accessory__content">
          <img
            src="assets/img/keras.svg"
            alt="keras logo"
            className="accessory__img"
          />
        </div>
        <div className="accessory__content">
          <img
            src="assets/img/pytorch.svg"
            alt="pytorck logo"
            className="accessory__img"
          />
        </div>
        <div className="accessory__content">
          <img
            src="assets/img/opencv-seeklogo.com.svg"
            alt="open cv logo"
            className="accessory__img"
          />
        </div>
        <div className="accessory__content">
          <img
            src="assets/img/spark.svg"
            alt="spark logo"
            className="accessory__img"
          />
        </div>
      </div>
    </section>
  );
};

export default Technologies;
