import React from "react";

const Services = () => {
  return (
    <section className="decoration section bd-container" id="decoration">
      <h2 className="section-title">
        Apply to your data <br /> the best services
      </h2>
      <div className="decoration__container bd-grid">
        <div className="decoration__data">
          <img
            src="assets/img/data_analyse.svg"
            alt=""
            className="decoration__img"
          />
          <h3 className="decoration__title">
            Data Architecture and BI Reporting
          </h3>
        </div>
        <div className="decoration__data">
          <img
            src="assets/img/custor_business.svg"
            alt=""
            className="decoration__img"
          />
          <h3 className="decoration__title">
            Custom Business Application Development
          </h3>
        </div>
        <div className="decoration__data">
          <img src="assets/img/cloud.svg" alt="" className="decoration__img" />
          <h3 className="decoration__title">Cloud Architecture</h3>
        </div>
        <div className="decoration__data">
          <img src="assets/img/agile.svg" alt="" className="decoration__img" />
          <h3 className="decoration__title">DevOps and Agile Integration</h3>
        </div>
        <div className="decoration__data">
          <img
            src="assets/img/strategy.svg"
            alt=""
            className="decoration__img"
          />
          <h3 className="decoration__title">IT Strategy Services</h3>
        </div>
        <div className="decoration__data">
          <img
            src="assets/img/application.svg"
            alt=""
            className="decoration__img"
          />
          <h3 className="decoration__title">
            Enterprise Application Integration
          </h3>
        </div>
      </div>
    </section>
  );
};

export default Services;
