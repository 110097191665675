import React from "react";

const About = () => {
  return (
    <section className="share section bd-container" id="share">
      <div className="share__container bd-grid">
        <div className="share__data">
          <h2 className="section-title-center">Why Choose Us</h2>
          <p className="share__description">
            UsefulData is a Paris based consulting firm with expertise in data
            analysis, model building, digital transformation, cloud integration,
            and IT strategy using cutting edge technology and groundbreaking
            tool sets.
          </p>
          <a href="#contact" className="button">
            Learn More
          </a>
        </div>
        <div className="share__img">
          <img src="assets/img/about.svg" alt="" />
        </div>
      </div>
    </section>
  );
};

export default About;
