import React from "react";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

const ScrollTop = () => {
  return (
    <a href="#" className="scrolltop" id="scroll-top">
      <ArrowUpwardIcon className="scrolltop__icon" />
    </a>
  );
};

export default ScrollTop;
