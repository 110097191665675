import React, { useState, useEffect } from "react";
import AppsIcon from "@material-ui/icons/Apps";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ToggleOffOutlinedIcon from "@material-ui/icons/ToggleOffOutlined";
import ToggleOnOutlinedIcon from "@material-ui/icons/ToggleOnOutlined";

const Header = () => {
  const [toggle, setToggle] = useState(true);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  /*==================== HANDLE THEME ====================*/
  const handleTheme = () => {
    document.body.classList.toggle("dark-theme");
    setToggle((toggle) => !toggle);
  };
  /*=====================================================*/

  /*==================== HANDLE MENU ====================*/
  const handleMenu = () => {
    document.getElementById("nav-menu").classList.toggle("show-menu");
    setMenuIsOpen((menuIsOpen) => !menuIsOpen);
  };

  const removeMenu = () => {
    document.getElementById("nav-menu").classList.remove("show-menu");
    setMenuIsOpen(false);
  };
  /*==============================================================*/

  /*==================== HANDLE HEADER SHADOW ====================*/
  const scrollHeader = () => {
    const nav = document.getElementById("header");
    // When the scroll is greater than 200 viewport height, add the scroll-header class to the header tag
    if (window.scrollY >= 200) nav.classList.add("scroll-header");
    else nav.classList.remove("scroll-header");
  };
  /*===========================================================================*/

  /*==================== HANDLE ACTIVE SECTION HIGHLIGHTER ====================*/
  const sections = document.querySelectorAll("section[id]");

  const scrollActive = () => {
    const scrollY = window.pageYOffset;

    sections.forEach((current) => {
      const sectionHeight = current.offsetHeight;
      const sectionTop = current.offsetTop - 50;
      const sectionId = current.getAttribute("id");

      if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
        document
          .querySelector(".nav__menu a[href*=" + sectionId + "]")
          .classList.add("active-link");
      } else {
        document
          .querySelector(".nav__menu a[href*=" + sectionId + "]")
          .classList.remove("active-link");
      }
    });
  };
  /*=================================================================*/

  /*==================== HANDLE SCROLLTOP BUTTON ====================*/
  const scrollTop = () => {
    const scrollTop = document.getElementById("scroll-top");
    // When the scroll is higher than 560 viewport height, add the show-scroll class to the a tag with the scroll-top class
    if (window.scrollY >= 560) scrollTop.classList.add("show-scroll");
    else scrollTop.classList.remove("show-scroll");
  };

  /*=================================================================*/

  useEffect(() => {
    window.addEventListener("scroll", scrollHeader);
    window.addEventListener("scroll", scrollActive);
    window.addEventListener("scroll", scrollTop);
  }, []);

  return (
    <header className="l-header" id="header">
      <nav className="nav bd-container">
        <a href="#" className="nav__logo">
          <img src="assets/img/logo.svg" alt="useful data logo" />
          <h2>UsefulData</h2>
        </a>
        <div className="nav__menu" id="nav-menu">
          <ul className="nav__list">
            <li className="nav__item" onClick={() => removeMenu()}>
              <a href="#home" className="nav__link active-link">
                Home
              </a>
            </li>
            <li className="nav__item" onClick={() => removeMenu()}>
              <a href="#share" className="nav__link">
                About
              </a>
            </li>
            <li className="nav__item" onClick={() => removeMenu()}>
              <a href="#decoration" className="nav__link">
                Services
              </a>
            </li>
            <li className="nav__item" onClick={() => removeMenu()}>
              <a href="#accessory" className="nav__link">
                Technologies
              </a>
            </li>
            <li className="nav__item" onClick={() => removeMenu()}>
              <a href="#contact" className="nav__link">
                Contact
              </a>
            </li>
            <li onClick={() => handleTheme()}>
              <div className="change-theme">
                {toggle ? (
                  <ToggleOffOutlinedIcon
                    id="theme-button"
                    className="toggle__off"
                  />
                ) : (
                  <ToggleOnOutlinedIcon
                    id="theme-button"
                    className="toggle__on"
                  />
                )}
              </div>
            </li>
          </ul>
        </div>
        <div
          className="nav__toggle"
          id="nav-toggle"
          onClick={() => handleMenu()}>
          {menuIsOpen ? <HighlightOffIcon /> : <AppsIcon />}
        </div>
      </nav>
    </header>
  );
};

export default Header;
