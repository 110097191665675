import React from "react";

const Home = () => {
  return (
    <section className="home" id="home">
      <div className="home__container bd-container bd-grid">
        <div className="home__img">
          <img src="assets/img/home.svg" alt="" />
        </div>
        <div className="home__data">
          <h1 className="home__title">
            Take advantage of your data by giving them a meaning
          </h1>
          <a href="#contact" className="button">
            Learn More
          </a>
        </div>
      </div>
    </section>
  );
};

export default Home;
