import React from "react";

const Contact = () => {
  return (
    <section className="send section" id="contact">
      <div className="send__container bd-container bd-grid">
        <div className="send__content">
          <h2 className="section-title-center send__title">Contact us</h2>
          <p className="send__description">
            Learn more about our services, enter your address e-mail and we will
            get back to you within 48 hours.
          </p>
          <form action="">
            <div className="send__direction">
              <input
                type="email"
                placeholder="email address"
                className="send__input"
              />
              <a href="mailto: contact@usefuldata.com" className="button">
                Send
              </a>
            </div>
          </form>
        </div>
        <div className="send__img">
          <img src="assets/img/contact-img.svg" alt="" />
        </div>
      </div>
    </section>
  );
};

export default Contact;
